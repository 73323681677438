import axios from "axios";

const GlobalSvc = {
  async getPSPDetailsByGuid(guid) {
    try {
      const response = await axios.get(
        `${process.env.VUE_APP_FIXIT_PAY_API_URL}/api/v1/session/${guid}/action/psp-details`
      );
      if (response.status == 200) {
        return response.data.dto;
      }
    } catch (err) {
      console.log(err);
    }
  },
};

export default GlobalSvc;
