import Vue from "vue";
import VueRouter from "vue-router";
import CheckoutView from "@/views/CheckoutView.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Checkout",
    component: CheckoutView,
    props: (route) => ({
      sessionGuid: route.query.session_id,
      parentUrl: route.query.parent_url,
    }),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
