import GlobalSvc from "@/services/global-svc";

const state = {
  sessionId: "",
};

const getters = {
  GET_SESSION_ID(state) {
    return state.sessionId;
  },
};

const mutations = {
  SET_SESSION_ID(state, value) {
    state.sessionId = value;
  },
};

const actions = {
  async fetchSessionId({ commit }, value) {
    let data = await GlobalSvc.getPSPDetailsByGuid(value);
    commit("SET_SESSION_ID", data.pspSessionId);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
