<template>
  <div class="container">
    <div class="content" :class="getCustomClass">
      <img src="https://umbraco.fixit.no/media/1348/fixit_logo_lilla.png" />
    </div>
    <base-spinner v-if="isLoading"></base-spinner>

    <div class="content" :style="{ width: isSession ? '100%' : '80%' }">
      <div
        id="checkout-container"
        height="100vh"
        class="checkout"
        :style="{
          backgroundColor: isSession ? '#f8f8f8' : 'transparent',
        }"
      ></div>
      <div v-if="isPaid" class="success">
        <span class="fxt-radio-on"></span>
        <p>Betaling fullført. Du kan nå lukke dette vinduet.</p>
      </div>
      <div v-if="isCancel" class="error">
        <span class="fxt-close" style="color: rgba(234, 65, 42, 1)"></span>
        <p style="color: rgba(234, 65, 42, 1)">
          Betalingen er avbrutt av bruker.
        </p>
      </div>
      <div v-if="isExpired" class="error">
        <span class="fxt-clock" style="color: rgba(234, 65, 42, 1)"></span>
        <p style="color: rgba(234, 65, 42, 1)">
          Denne betalingssesjonen er ugyldig eller utløpt.
        </p>
      </div>
      <div v-if="isError" class="error">
        <span class="fxt-close" style="color: rgba(234, 65, 42, 1)"></span>
        <p style="color: rgba(234, 65, 42, 1)">{{ errorMesage }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { embed } from "@dintero/checkout-web-sdk";
import BaseSpinner from "../components/BaseSpinner.vue";

export default {
  components: {
    BaseSpinner,
  },
  data() {
    return {
      isExpired: false,
      isCancel: false,
      isSession: false,
      isPaid: false,
      isLoading: false,
      isError: false,
      errorMesage: "",
    };
  },
  props: ["sessionGuid", "parentUrl"],
  computed: {
    sessionId() {
      return this.$store.getters["global/GET_SESSION_ID"];
    },
    getCustomClass() {
      return this.isCancel || this.isError || this.isExpired || this.isPaid
        ? "custom"
        : "";
    },
  },
  methods: {
    showCheckout() {
      this.isLoading = true;
      const container = document.getElementById("checkout-container");
      embed({
        container,
        sid: this.sessionId,
        language: "no",
        onSession: (_) => {
          this.isSession = true;
          this.postMsg(_);
        },
        onPayment: (_, checkout) => {
          this.isPaid = true;
          this.isSession = false;
          checkout.destroy();
          this.postMsg(_);
        },
        onSessionCancel: (_, checkout) => {
          this.isCancel = true;
          this.isSession = false;
          checkout.destroy();
          this.postMsg(_);
        },
        onSessionNotFound: (_, checkout) => {
          this.isExpired = true;
          this.isSession = false;
          checkout.destroy();
          this.postMsg(_);
        },
        onPaymentError: (_, checkout) => {
          this.isError = true;
          this.isSession = false;
          console.log(_);
          console.log(checkout);
          this.errorMesage = _.type;
          checkout.destroy();
          this.postMsg(_);
        },
      }).finally(() => {
        this.isLoading = false;
      });
    },
    postMsg(res) {
      if (window.opener) {
        window.opener.postMessage({ paymentStatus: res.type }, this.parentUrl);
      } else if (this.$route.query.app == "true") {
        window.Flutter.postMessage(
          JSON.stringify({
            paymentStatus: res.type,
            transactionId: res?.transaction_id,
          })
        );
      }
    },
    async getPSPDetails() {
      await this.$store.dispatch("global/fetchSessionId", this.sessionGuid);
    },
  },
  async created() {
    if (!(this.$route.query.transaction_id || this.$route.query.error)) {
      await this.getPSPDetails();
      this.showCheckout();
    } else {
      /**
       * If the parent application uses dintero's checkout url (on a different domain), then
       * the parent's returnUrl can be set to "https://checkout.fixit-demo.no?parentUrl={parentUrl}"
       *
       * Events in the dintero payment window will be appended to the return url, meaning on payment
       * success/cancel/failure it will be appended as a query and sent back here. Caught events will
       * be posted back to the parent application.
       */
      if (this.$route.query.transaction_id) {
        this.postMsg({ type: "SessionPaymentAuthorized" });
      } else if (this.$route.query.error) {
        this.$route.query.error === "cancelled"
          ? this.postMsg({ type: "SessionCancel" })
          : this.postMsg({ type: "SessionPaymentError" });
      }
    }
  },
};
</script>

<style scoped>
.error p,
.success p {
  font-size: 28px;
  font-weight: 300;
  align-content: center;
  margin-top: 0px;
  color: rgba(71, 36, 73, 1);
}
.container {
  width: 100%;
  height: 100vh;
  padding: 0 !important;
  background: linear-gradient(#bfe5e8, #efddd1);
}
.content {
  text-align: center;
  width: 80%;
  margin: auto;
}
.content img {
  margin-top: 53px;
  margin-bottom: 70px;
  width: 164px;
  height: 72px;
}
.content p {
  padding-bottom: 30px;
  font-size: 28px;
  font-weight: 300;
  line-height: normal;
}
.content.custom img {
  margin-top: 89px;
  margin-bottom: 0px;
}
.checkout {
  margin: 0;
  padding: 0;
}

span {
  font-weight: 500;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 96px;
  line-height: 1;
  padding: 39px;
  margin-top: 19px;
  margin-bottom: 19px;
}

@media only screen and (min-width: 768px) {
  .container {
    max-width: 550px;
    min-height: 600px;
    height: 100%;
    padding-top: 20px !important;
    margin: 80px auto !important;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 15px 0px;
  }
  .content p {
    padding-bottom: 50px;
    font-size: 22px;
    font-weight: 300;
    line-height: 32.62px;
  }
}

@media (max-height: 600px) {
  .container {
    min-height: 100vh;
  }
  .content p {
    padding-bottom: 10px;
    font-size: 18px;
    font-weight: 200;
  }
}
</style>
